<template>
  <div>
    <pm-DataTable :value="msgAll" id="msg_comunic"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
        responsiveLayout="scroll">
      <pm-Column field="nome" sortable header="" style="font-size: 14px;vertical-align:middle !important">
        <template #body="{data}">
          <div @click="abrirMensagem(data)" style="cursor: pointer;">
            <span class="badge badge-prym" v-if="data.setor_de_recebimento != ' ' && env == 1"> {{data.setor_de_recebimento}} </span>
            <span class="badge badge-prym" style="margin-left:5px" v-if="data.servidor_nome_enviou != '' && env == 0" >{{data.servidor_nome_enviou}}</span>
            <span class="badge badge-prym" style="margin-left:5px" v-if="data.servidor_nome_resposta != '' && env == 1" >{{data.servidor_nome_resposta}}</span>
            <span style="margin-left:20px"> {{data.titulo}} </span>
            <div style="float:right;"><span class="badge badge-seg">{{data.data_formatada}} {{data.hora}}</span></div>
          </div>
        </template>
      </pm-Column>
    </pm-DataTable>


    <div class="p-paginator p-component p-paginator-bottom" >
      <div class="p-paginator-pages" style="display: inline-flex;">
        <div  v-for="n in links" :key="n">
          <button class="p-paginator-next p-paginator-element p-link" @click="paginas(n)" v-if="!n.active"
              style="border: 0px solid #ebebeb;border-radius: 0;">
            <p class="p-paginator-icon " v-html="n.label"  style="margin-bottom:0px !important;"></p>
          </button>
          <button class="p-paginator-page p-paginator-element p-link p-highlight" @click="paginas(n)" v-if="n.active" style="border-radius: 0;">{{n.label}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "listar_mensagens",
  props: {
    msgAll: [],
    links:[],
    env:0,
  },
  data() {
    return {};
  },
  beforeMount(){
  },
  methods: {
    abrirMensagem(data){
      this.$emit('abrirMensagem', data, this.env);
    },
    paginas(n){
      this.$emit('paginas', n);
    },
  },
};
</script>

<style scoped>
  .badge-prym{
    color: #fff;
    background-color: #28a0ff;
    font-size: 11px;
    font-weight: 500 !important;
  }
  .badge-seg{
    color: #fff;
    background-color: #d4d4d4;
    font-size: 11px;
    font-weight: 500 !important;
  }
</style>
